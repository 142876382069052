import React, { useEffect } from 'react'
import Link from '../components/shared/Link/Link'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Generic
import BasicPage from '../components/shared/BasicPage/BasicPage'

const ComplaintsPage = (props) => {
  useEffect(() => {
    window.location.href = 'https://docs.google.com/document/d/1D35iKQD9QhOHNPy0CzL1awY5vL_MinjpWHwwwwm_AUM/edit'
  }, [])

  return (
    <>
      <Seo title='Complaints' slug={props.location.pathname} />
      <BasicPage>
        <h2 className='h5' data-number-before='1'>
          Redirecting you to our complaints policy
        </h2>
        <p className='p' data-number-before='1'>
          If that doesn't work, please follow
          <Link
            url='https://docs.google.com/document/d/1D35iKQD9QhOHNPy0CzL1awY5vL_MinjpWHwwwwm_AUM'
            className='opacity-75 text-primary hover:underline'
          >
            {' '}this link
          </Link>.
        </p>
      </BasicPage>
    </>
  )
}

export default ComplaintsPage
